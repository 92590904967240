<template>
  <c-select
    v-model="sortByMutable"
    hide-details
    :items="sortItems"
    :placeholder="$t('marketplace.sort_by')"
    item-text="label"
    item-value="id"
    height="30"
    clearable
  />
</template>

<script>
  import CSelect from '@clickadilla/components/ui/CSelect.vue'

  export default {
    name: 'FlatDealSortingField',
    components: {
      CSelect
    },
    props: {
      options: {
        type: Object,
        default: () => ({})
      }
    },
    computed: {
      sortItems() {
        return [
          {
            id: 1,
            sortBy: 'originalPrice',
            sortDesc: true,
            label: this.$t('main.price') + this.$t('main.desc')
          },
          {
            id: 2,
            sortBy: 'originalPrice',
            sortDesc: false,
            label: this.$t('main.price') + this.$t('main.asc')
          },
          {
            id: 3,
            sortBy: 'flatBid',
            sortDesc: true,
            label: this.$t('marketplace.cpm') + this.$t('main.desc')
          },
          {
            id: 4,
            sortBy: 'flatBid',
            sortDesc: false,
            label: this.$t('marketplace.cpm') + this.$t('main.asc')
          },
          {
            id: 5,
            sortBy: 'country',
            sortDesc: true,
            label: this.$t('marketplace.countries_tier') + this.$t('main.desc')
          },
          {
            id: 6,
            sortBy: 'country',
            sortDesc: false,
            label: this.$t('marketplace.countries_tier') + this.$t('main.asc')
          },
          {
            id: 7,
            sortBy: 'site',
            sortDesc: true,
            label: this.$t('marketplace.domain') + this.$t('main.desc')
          },
          {
            id: 8,
            sortBy: 'site',
            sortDesc: false,
            label: this.$t('marketplace.domain') + this.$t('main.asc')
          }
        ]
      },
      sortByMutable: {
        get() {
          return this.sortItems.find(
            (item) => item.sortDesc === this.options.sortDesc[0]
              && item.sortBy === this.options.sortBy[0]
          )
        },
        set(selectId) {
          const foundSort = this.sortItems.find((item) => item.id === selectId)
          if (foundSort) {
            if (foundSort.sortBy !== this.options.sortBy[0]) {
              this.$emit('change-sort-by', [foundSort.sortBy])
            }
            if (foundSort.sortDesc !== this.options.sortDesc[0]) {
              this.$emit('change-sort-desc', [foundSort.sortDesc])
            }
          } else {
            if (this.options.sortBy[0] !== 'isHotOffer') {
              this.$emit('change-sort-by', ['isHotOffer'])
            }
            if (this.options.sortDesc[0] !== true) {
              this.$emit('change-sort-desc', [true])
            }
          }
        }
      }
    }
  }
</script>
