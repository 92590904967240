<template>
  <v-card elevation="0" class="secondary-lighten pa-12 text-center">
    <div class="black--text">
      {{ $t('marketplace.flat_deal_empty') }}
    </div>
    <v-icon class="mt-8 mt-sm-12" color="primary" size="34">
      $lamp
    </v-icon>
    <div class="mt-3 secondary-darken--text">
      {{ $t('marketplace.change_your_filters') }}
    </div>
    <c-btn
      color="primary"
      class="mt-5"
      depressed
      :to="{ name: routeNames.CREATE_CAMPAIGN }"
      :label="$t('main.create_campaign')"
    />
  </v-card>
</template>

<script>
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import routeNames from '@/types/route-names.js'

  export default {
    name: 'FlatDealEmpty',
    components: { CBtn },
    data() {
      return {
        routeNames
      }
    }
  }
</script>
