<template>
  <v-container fluid class="px-2">
    <v-row class="justify-md-start pt-2">
      <v-col v-bind="colParams" class="pa-1">
        <c-autocomplete
          :value="filters.site"
          :items="settings.sites"
          item-text="domain"
          item-value="domain"
          :placeholder="$t('marketplace.website')"
          :multiple="false"
          :all-selected-is-active="false"
          :loading="settings.sitesAreLoading"
          clearable
          hide-details
          @change="changeFilters('site', $event)"
        >
          <template #prepend-inner>
            <v-img
              v-if="selectedSite?.site_report?.favicon_url"
              :src="selectedSite.site_report.favicon_url"
              contain
              width="20"
              height="20"
              max-height="20"
              max-width="20"
              class="d-block my-1"
            />
          </template>
          <template #item="{ item }">
            <v-img
              v-if="item.site_report?.favicon_url"
              :src="item.site_report.favicon_url"
              contain
              width="20"
              height="20"
              max-height="20"
              max-width="20"
              class="d-block my-1"
            />
            <span class="ml-2">{{ item.domain }}</span>
          </template>
        </c-autocomplete>
      </v-col>
      <v-col v-bind="colParams" class="pa-1">
        <c-autocomplete
          :value="filters.adFormatIds"
          :items="settings.getFlatDealAdFormats"
          item-text="name"
          item-value="id"
          :placeholder="$t('main.ad_format')"
          :all-selected-is-active="false"
          hide-details
          clearable
          @change="changeFilters('adFormatIds', $event)"
        />
      </v-col>
      <v-col v-bind="colParams" class="pa-1">
        <c-autocomplete
          :value="filters.adPlacementIds"
          :items="filteredPlacementTypesByAdFormatId"
          :multiple="false"
          :placeholder="$t('marketplace.ad_placement')"
          :all-selected-is-active="false"
          :disabled="!filteredPlacementTypesByAdFormatId.length"
          item-text="name"
          item-value="id"
          hide-details
          clearable
          @change="changeFilters('adPlacementIds', $event)"
        />
      </v-col>
      <v-col v-bind="colParams" class="pa-1">
        <c-select
          :value="filters.country"
          :items="settings.flatDealTiers"
          :placeholder="$t('marketplace.countries_tier')"
          hide-details
          clearable
          @change="changeFilters('country', $event)"
        />
      </v-col>
      <v-col v-bind="colParams" class="pa-1">
        <c-select
          :value="filters.trafficType"
          :items="trafficTypes"
          :placeholder="$t('marketplace.device_type')"
          item-text="label"
          item-value="value"
          hide-details
          clearable
          selection-slot
          item-slot
          @change="changeFilters('trafficType', $event)"
        >
          <template #selection="{ item }">
            <div>
              <v-icon color="black" size="16" class="mr-2 mt-n1">
                {{ FlatDeal.trafficTypeWitchIcon[item.value] }}
              </v-icon>
              {{ item.label }}
            </div>
          </template>
          <template #item="{ item }">
            <div>
              <v-icon color="black" size="16" class="mr-2 mt-n1">
                {{ FlatDeal.trafficTypeWitchIcon[item.value] }}
              </v-icon>
              {{ item.label }}
            </div>
          </template>
        </c-select>
      </v-col>
      <v-col v-bind="colParams" class="pa-1">
        <c-text-field
          :value="filters.id"
          placeholder="ID"
          type="number"
          hide-details
          clearable
          @input="changeFilters('id', $event ? +$event : null)"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import CSelect from '@clickadilla/components/ui/CSelect.vue'
  import CAutocomplete from '@clickadilla/components/ui/CAutocomplete.vue'
  import CTextField from '@clickadilla/components/ui/CTextField.vue'
  import FlatDeal from '../../services/classes/FlatDeal.js'

  export default {
    name: 'FlatDealFilters',
    components: { CAutocomplete, CSelect, CTextField },
    props: {
      filters: {
        type: Object,
        required: true
      },
      isLoading: {
        type: Boolean,
        required: true
      },
      settings: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        FlatDeal
      }
    },
    computed: {
      colParams() {
        return this.$vuetify.breakpoint.smAndDown ? { cols: '12', sm: '6' } : {}
      },
      trafficTypes() {
        return this.settings.flatDealTrafficTypes.map((type) => ({
          value: type,
          label: type[0].toUpperCase() + type.substr(1)
        }))
      },
      filteredPlacementTypesByAdFormatId() {
        if (!this.filters.adFormatIds) return []

        return this.settings.placementTypes
          .filter((adPlacement) => this.filters.adFormatIds.includes(adPlacement.adFormat.id))
      },
      selectedSite() {
        return this.settings.sites.find((site) => site.domain === this.filters.site)
      }
    },
    methods: {
      changeFilters(key, value) {
        if (this.filters[key] === value) return

        this.$emit('change', { key, value })
      }
    }
  }
</script>
