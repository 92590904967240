<template>
  <div>
    <ads-spot
      v-if="adNetwork.settings.adsSpots.marketplace.type !== null"
      :spot="adNetwork.settings.adsSpots.marketplace"
      name="marketplace"
    />
    <v-card elevation="0" :loading="isLoading">
      <v-card-title class="white--text black font-weight-medium pa-2 pl-6">
        {{ $t('marketplace.marketplace') }}
      </v-card-title>
      <v-card-text class="py-0">
        <flat-deal-filters
          class="mb-5"
          :filters="filters"
          :is-loading="isLoading"
          :settings="settingsFilters"
          @change="changeFilters($event)"
        />
        <v-container fluid class="px-0 pt-0 mb-2">
          <v-row class="px-2 mt-2">
            <v-col cols="12" sm="6" md="4" lg="2" class="pa-1">
              <flat-deal-sorting-field
                :options="options"
                @change-sort-by="changeOptions($event, 'sortBy')"
                @change-sort-desc="changeOptions($event, 'sortDesc')"
              />
            </v-col>
          </v-row>
        </v-container>
        <flat-deal-card
          v-for="(flatDeal) in flatDeals"
          :key="flatDeal.id"
          :flat-deal="flatDeal"
          class="mb-4"
          @send-request="sendGtm(GTM_EVENTS.SEND_REQUEST)"
          @flat-buy-click="sendGtm(GTM_EVENTS.BUY_CARD)"
        />
      </v-card-text>
      <v-card-text v-if="!flatDeals.length && !isLoading && !sitesAreLoading">
        <flat-deal-empty />
      </v-card-text>
      <v-card-text class="pa-0">
        <data-table-footer
          :items-per-page="options.itemsPerPage"
          :page-count="pageCount"
          :current-page="options.page"
          @change-items-per-page="changeOptions($event, 'itemsPerPage')"
          @change-current-page="changeOptions($event, 'page')"
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import DataTableFooter from '@clickadilla/components/ui/DataTableFooter.vue'
  import debounce from 'debounce'
  import datatableFormatter from '@clickadilla/components/utils/datatable-formatter.js'
  import { mapGetters, mapState } from 'vuex'
  import { GTM_EVENTS, GTM_EVENTS_CATEGORY } from '@clickadilla/components/constants/gtm-events.js'
  import handleErrors from '@/services/handleErrors.js'
  import { flatDealsRepository, sitesRepository } from '@/services/repository-factory.js'
  import FlatDealCard from '@/views/Marketplace/FlatDealCard.vue'
  import FlatDeal from '@/services/classes/FlatDeal.js'
  import FlatDealFilters from '@/views/Marketplace/FlatDealFilters.vue'
  import AdsSpot from '@/components/adsSpot.vue'
  import gtmPush from '@/services/utils/gtm-push.js'
  import FlatDealSortingField from '@/views/Marketplace/FlatDealSortingField.vue'
  import FlatDealEmpty from '@/views/Marketplace/FlatDealEmpty.vue'

  export default {
    name: 'Marketplace',
    components: {
      FlatDealEmpty,
      FlatDealSortingField,
      AdsSpot,
      FlatDealFilters,
      FlatDealCard,
      DataTableFooter
    },
    data() {
      return {
        GTM_EVENTS,
        flatDeals: [],
        isLoading: false,
        sitesAreLoading: false,
        pageCount: 1,
        options: {
          page: 1,
          itemsPerPage: 15,
          sortBy: ['isHotOffer'],
          sortDesc: [true]
        },
        filters: {
          adFormatIds: null,
          adPlacementIds: null,
          site: null,
          country: null,
          id: null,
          trafficType: null
        },
        debounceFetchTable: null,
        sites: []
      }
    },
    computed: {
      ...mapState('settings', [
        'adNetwork',
        'flatDealTiers',
        'flatDealTrafficTypes',
        'placementTypes'
      ]),
      ...mapState('campaigns', ['pricingModels']),
      ...mapGetters('settings', ['getFlatDealAdFormats']),
      headers() {
        return [
          { value: 'id' },
          {
            value: 'adFormatIds',
            data: 'placementType.ad_format_id'
          },
          { value: 'adPlacementIds', data: 'placementType.id' },
          { value: 'site', data: 'site.domain' },
          { value: 'country', data: 'countries_name' },
          { value: 'pricingModel', data: 'pricing_model' },
          { value: 'trafficType', data: 'traffic_type' },
          { value: 'originalPrice', data: 'original_price' },
          { value: 'flatBid', data: 'flat_bid' },
          { value: 'isHotOffer', data: 'is_hot_offer' }
        ]
      },
      settingsFilters() {
        return {
          getFlatDealAdFormats: this.getFlatDealAdFormats,
          placementTypes: this.placementTypes,
          flatDealTiers: this.flatDealTiers,
          flatDealTrafficTypes: this.flatDealTrafficTypes,
          sites: this.sites,
          sitesAreLoading: this.sitesAreLoading
        }
      },
      flatDealAdFormatIds() {
        return this.getFlatDealAdFormats.map(({ id }) => id)
      }
    },
    async created() {
      await this.fetchSites()
      this.getQueryParams()
      this.debounceFetchTable = debounce(this.fetchFlatDeals, 500)
      this.fetchFlatDeals()
      gtmPush({
        event: GTM_EVENTS.OPEN_MARKETPLACE,
        open_marketplace: Date.now()
      })
    },
    methods: {
      sendGtm(event) {
        gtmPush({
          event,
          event_category: GTM_EVENTS_CATEGORY.FEATURE
        })
      },
      async fetchFlatDeals() {
        this.isLoading = true
        const params = datatableFormatter({
          headers: this.headers,
          search: this.filters,
          options: this.options
        })
        try {
          const response = await flatDealsRepository.table(params)
          this.flatDeals = response.data?.map((deal) => new FlatDeal(deal)) ?? []
          this.pageCount = Math.ceil(response.recordsFiltered / this.options.itemsPerPage)
        } catch (error) {
          handleErrors(error)
        }
        this.isLoading = false
      },
      async fetchSites() {
        this.sitesAreLoading = true
        try {
          this.sites = await sitesRepository.marketplaceList()
        } catch (error) {
          handleErrors(error)
        }
        this.sitesAreLoading = false
      },
      async changeOptions(val, key) {
        this.options[key] = val
        if (key !== 'page') {
          this.options.page = 1
        }
        await this.setQueryParams()
        this.debounceFetchTable()
      },
      async changeFilters({ key, value }) {
        if (key === 'adFormatIds') {
          this.filters.adPlacementIds = null
        }
        this.filters[key] = value
        this.options.page = 1
        await this.setQueryParams()
        this.debounceFetchTable()
        if (value !== null) {
          const transformValue = Array.isArray(value) ? value.join(', ') : value
          gtmPush({
            event: GTM_EVENTS.MARKETPLACE_FILTER,
            event_category: GTM_EVENTS_CATEGORY.FEATURE,
            custom_map: { dimension9: 'FlatDealFilter' },
            FlatDealFilter: `${key}, ${transformValue}`
          })
        }
      },
      getQueryParams() {
        const { query } = this.$route
        this.filters = {
          id: +query.id || null,
          adFormatIds:
            (+query.adFormatIds && [+query.adFormatIds])
            || (Array.isArray(query.adFormatIds)
              && query.adFormatIds.map((id) => +id).filter((id) => this.flatDealAdFormatIds.includes(id)))
            || null,
          adPlacementIds: this.placementTypes.find((placementType) => placementType.id === +query.adPlacementIds)
            ? +query.adPlacementIds
            : null,
          site: this.sites.find(({ domain }) => domain === query.site)?.domain ?? null,
          country: this.flatDealTiers.find((country) => country === query.country) ?? null,
          trafficType:
            this.flatDealTrafficTypes.find((trafficType) => trafficType === query.trafficType)
            ?? null
        }
        this.options = {
          page: Number(query.page) || this.options.page,
          itemsPerPage: Number(query.itemsPerPage) || this.options.itemsPerPage,
          sortBy: query.sortBy ? [query.sortBy] : this.options.sortBy,
          sortDesc: query.sortDesc ? [!!query.sortDesc] : this.options.sortDesc
        }
      },
      async setQueryParams() {
        const actualFilter = Object.entries(this.filters).reduce((acc, [key, value]) => {
          if (value !== null) {
            acc[key] = value
          }
          return acc
        }, {})
        await this.$router.replace({ name: 'Marketplace', query: { ...actualFilter, ...this.options } })
      }
    }
  }
</script>
